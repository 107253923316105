.image-carousel {
    max-width: 1200px;
    margin: 0 auto 3rem;
}

.image-carousel ul li {
    list-style: none;
}

.carousel.carousel-slider {
    overflow: visible;
}

.carousel .slide img {
    width: auto;
}

.carousel img {
    width: auto;
}

.carousel .control-dots {
    bottom: -45px
}

.carousel .control-dots .dot {
    background: var(--lightgrey);
    width: 12px;
    height: 12px;
    box-shadow: none;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background: var(--darkgrey);
}

@media (max-width: 1200px) {
    .image-carousel {
        max-width: 950px;
      }
}
@media (max-width: 1000px) {
    .image-carousel {
        max-width: 350px;
      }
}