/* VARIABLES */
:root {
  --white: #ffffff;
  --black: #000000;
  --darkgrey: #282828;
  --lightgrey: #9d9d99;
  --yellow: #ffcc00;
  --blue: #64b7d8;
  --line: #f0f0f0;
}

/* FONTS */
@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url('/public/assets/fonts/SourceSansPro-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro-SemiBold';
  src: url('/public/assets/fonts/SourceSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: url('/public/assets/fonts/SourceSansPro-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

/* GLOBAL */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SourceSansPro-Regular', "Arial", "sans-serif";
  color: var(--darkgrey);
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
body {
  background-color: var(--line);
}
img {
  display: block;
  margin: 0 auto;
}
h2 {
  font-family: 'SourceSansPro-Bold', "Arial", "sans-serif";
  font-display: swap;
  font-size: 2em;
}
h3 {
  font-family: 'SourceSansPro-Bold', "Arial", "sans-serif";
  font-display: swap;
  font-size: 1.3em;
  margin-top: 1em;
}
p {
  margin: 1.5em 0 0;
  font-size: 1.1em;
}
p:first-of-type {
  margin-top: 0;
}
ol {
  list-style-type: disc;
  margin: 0 0 0 1.2em;
  padding: 0;
}
ol li {
  font-size: 1.1em;
}
ul {
  margin: 1em 0 0 1.3em;
  padding: 0;
}
ul li {
  margin: 8px 0 0;
  padding-left: 10px;
  font-size: 1.1em;
  list-style-image: url('/public/assets/images/bullit.png');
}
ul li:first-of-type {
  margin-top: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
.headline--blue {
  color: var(--blue);
}
.headline--yellow {
  color: var(--yellow);
}
.images {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.images--no-resize {
  max-width: inherit;
  height: inherit;
  display: block;
  margin: 0 auto;
}
.images--mobile-view {
  display: none;
}
.images--desktop-view {
  display: block;
}
.txt--center {
  text-align: center;
}


/* LAYOUT */
#root {
  max-width: 2000px;
  margin: 0 auto;
  background-color: var(--white);
  box-shadow: 0px 0px 8px 0px rgb(157 157 153 / 80%);
}
.flex-container {
  display: flex;
  justify-content: space-between;
}
.flex-container--header {
  width: 100%;
  align-items: center;
}
.grid-container {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
}
.grid__row {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  justify-items: stretch;
  align-items: stretch;
}
.grid__row--half-width {
  grid-template-columns: 1fr 1fr;
}
.grid__row--one-third {
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 4rem;
}
.grid__row--one-forth {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 3rem;
}
.grid__col {
  width: 100%;
}
.grid-container a {
  color: var(--yellow);
}
.privacy a,
.legal a {
  color: var(--darkgrey);
}
.privacy a:hover,
.legal a:hover {
  color: var(--blue);
}


/* BUTTONS */
.grid-container .btn--yellow,
.grid-container .btn--yellow:hover {
  background-color: var(--yellow);
  color: var(--black);
  padding: 0.5em 1em;
  text-align: center;
  width: 230px;
  border: 0;
  font-weight: bold;
  font-size: 1em;
  display: block;
  text-decoration: none;
  margin-top: 15px;
  cursor: pointer;
}

/* NAVIGATION */
.navbar {
  background-color: var(--white);
  border-bottom: 4px solid var(--line);
  padding: 1em;
  position: sticky;
  top: 0;
  z-index: 99;
}
.language {
  border: 1px solid var(--lightgrey);
  font-family: 'SourceSansPro-Bold', "Arial", "sans-serif";
  font-display: swap;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 0.6em;
  color: var(--white); 
  font-size: 0.8em;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0;
}
.language--en {
  background-color: var(--yellow);
}
.language--de {
  background-color: var(--blue);
  margin-bottom: 5px;
}
.logo img {
  max-width: 250px;
  height: auto;
}


/* VIDEOS */
.youtube-consent {
  background-color: var(--darkgrey);
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
  display: block;
}
.youtube-consent--box {
  color: var(--white);
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.youtube-consent div p {
  color: var(--white);
}
.video-container {
  display: none;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/* TABLE */
table {
  width: 80%;
  border: 0;
  text-align: left;
  margin-top: 2em;
  border-collapse: collapse;
  table-layout: fixed;
}
th {
  font-family: 'SourceSansPro-Bold', "Arial", "sans-serif";
  font-display: swap;
  font-size: 1.4em;
  padding: 0 0 0.3em;
}
td {
  padding: 0.6em 0;
  vertical-align: top;
  border-top: 3px solid;
}
td:first-of-type {
  font-weight: bold;
}
td:last-of-type {
  text-align: right;
}
.table-fridge td {
  border-top-color: #64b7d8;
}
.table-screen {
  margin-left: 20%;
}
.table-screen td {
  border-top-color: #ffcc00;
}




/* FORM */
form {
  margin: 0;
  padding: 0;
}
select {
  border: 2px solid var(--yellow);
  width: 100%;
  border-radius: 4em;
  height: 35px;
  outline: none;
  padding: 0.3em 1em;
  background-color: var(--white);
}
input[type="text"],
input[type="tel"],
input[type="email"] {
  border: 2px solid var(--yellow);
  width: 100%;
  height: 30px;
  border-radius: 4em;
  padding: 1em;
  outline: none;
}
textarea {
  border: 2px solid var(--yellow);
  width: 100%;
  height: 100px;
  border-radius: 2em;
  padding: 1em;
  outline: none;
  resize: vertical;
}
label {
  font-size: 1.3em;
}
/* Style the checkmark/indicator */
.container-left {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-right: 25px;
  cursor: pointer;
  font-size: 1.3em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container-left:last-of-type {
  margin-right: 0px;
}
/* Hide the browser's default checkbox */
.container-left input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Hide the browser's default checkbox */
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.container-left .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid var(--yellow);
  border-radius: 50%;
}
/* When the checkbox is checked, add a blue background */
.checkmark--checked {
  background-color: var(--blue);
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 21px;
  height: 21px;
  border: 3px solid var(--white);
  border-radius: 50%;
}
.checkmark--unchecked {
  display: none;
}
.form--error {
  margin-top: 45px;
  display: block;
  font-size: 1em;
  color: #ff0000;
  margin-top: 0;
}


/* FOOTER */
#site-footer {
  font-size: 0.8em;
  background-color: var(--line);
  padding: 2rem 2rem 1rem 0;
}
.box {
  height: 4px;
  width: 50%;
  float: left;
}
.box--blue {
  background-color: var(--blue);
}
.box--yellow {
  background-color: var(--yellow);
}
.footer-link {
  padding-right: 1rem;
}
.footer-link:hover {
  color: var(--blue)
}
.address {
  padding-left: 2rem;
}

/* RESPONSIVE */

@media (max-width: 1550px) {
  .grid-container {
    padding: 0 8rem;
  }
}

@media (max-width: 1200px) {
  table {
    width: 100%;
  }
  .table-screen {
    margin: 30px 0 0;
  }
  .grid__row--one-third {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 1rem;
  }
}

@media (max-width: 1100px) {
  .grid-container {
    padding: 0 2rem;
  }
  .container-left,
  label {
    font-size: 1.1em;
  }
  .images--mobile-view {
    display: block;
  }
  .images--desktop-view {
    display: none;
  }
  .grid__row--one-forth {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: 2rem;
  }
}

@media (max-width: 915px) {
  .grid__row--half-width {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 1rem;
  }
  .flex-container--column {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  .flex-container--column--sm {
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: .2em 1em;
  }
  .logo img {
    width: 200px;
  } 
  .flex-container--column--sm {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .grid__row--one-forth {
    grid-template-columns: 1fr;
  }
}